import { object, ObjectEntries, ObjectSchema } from 'valibot';
import { WelcomeMessageBlankSchema } from '../../../schemes';

export function mergeBlankWelcomeMessageSchema<TEntries extends ObjectEntries>(
  schema: ObjectSchema<TEntries, undefined>
) {
  return object({
    ...schema.entries,
    welcome_message: WelcomeMessageBlankSchema,
  });
}
